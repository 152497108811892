<template>
  <div class="skillsgap-onboard">
    <b-container v-if="test" class="m-0 ">
      <b-row>
        <b-col v-if="!challengeAccepted" cols="12" lg="6"
               class="p-5 prevent-shift">
          <b-row>
            <b-col>
              <b-container v-if="userType === 'business'" class="p-0">
                <h2 id="business-header">
                  You’ve been invited to take a {{ test.test_name }} Skills Test
                </h2>
                <p>Welcome to the SolidProfessor Skills Analyzer! You’ve been invited by {{ test.first_name + ' ' + test.last_name }} to test your skills.</p>
                <b-link v-b-modal.modal-test-info>
                  Learn more about the {{ test.test_name }} test
                </b-link>
              </b-container>
              <b-container v-if="userType === 'personal'" class="p-0">
                <h2 id="personal-header">
                  Put your {{ test.name }} skills to the test
                </h2>
                <p>Welcome to the SolidProfessor Skills Analyzer! Take this assessment to test your knowledge with questions straight out of the SolidProfessor course library.</p>
                <b-link v-b-modal.modal-test-info>
                  Learn more about the {{ test.name }} test
                </b-link>
              </b-container>
              <b-modal id="modal-test-info" hide-footer>
                <h3 class="mb-4">
                  <b>{{ getTestName(test) }}</b>
                </h3>
                <p class="my-4">
                  {{ test.description }}
                </p>
                <p class="m-0 mt-4">
                  <b>Skills Analyzed</b>
                </p>
                <p class="m-0 mb-4">
                  {{ test.skills }}
                </p>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="p-1">
            <b-col>
              <h3>How It Works</h3>
              <p>
                This multiple-choice test will help you measure your technical skills. At the end, you’ll receive your
                overall score and a breakdown of how you did on each skill covered in the test. Plus, you’ll get
                personalized recommendations for tutorials to start improving or advancing your skills.
                <br>
                <br>
                Here are the details you need to know: <br>
                • It takes around {{ test.questions.length + ' minutes' }} to complete the test <br>
                • This test includes {{ test.questions.length }} multiple choice questions <br>
                • You can go back to change answers to questions <br>
                • You have 1 attempt <br>
              </p>
              <p class="py-3">
                Good luck!
              </p>
              <b-row class="my-4">
                <b-col cols="12" md="8">
                  <b-button id="get-started-button" variant="primary" block
                            @click="nextStep()">
                    Get Started
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="!user || !user.id" class="m-1 my-4">
                <b-col class="p-0">
                  <p class="m-0 bolded" style="display: inline-block;">
                    Already a SolidProfessor member?
                  </p>
                  <b-link :to="{ name: 'Login', query: { return_url: $route.path }}">
                    Login
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="challengeAccepted" cols="12" lg="6"
               class="p-4 prevent-shift">
          <b-row id="challenge-accepted">
            <b-col>
              <h2 class="mb-3 mt-5">
                Tell us where to send your results
              </h2>
              <p>Complete the form below so we can send you your Skills Analyzer results.</p>
              <p v-if="userType === 'business'" class="my-5">
                <b>Note:</b> Since this test was set up for multiple users at your organization, a copy of your results will also be shared with {{ test.first_name + ' ' + test.last_name }}
              </p>
              <p class="my-5">
                <i>This will not create a SolidProfessor member account.</i>
              </p>
              <b-row>
                <b-col cols="10">
                  <b-form>
                    <b-form-group
                      id="input-group-1"
                      :label="(userType === 'business' ? 'Company ': '') + 'Email Address:'"
                      class="m-0"
                      label-for="input-1">
                      <b-form-input
                        id="input-1"
                        v-model="form.email"
                        v-validate="'email|required|max:60'"
                        name="Email"
                        type="text"
                        class="m-0" />
                      <small v-if="errors" class="m-2">
                        <span class="text-danger">{{ errors.first('Email') }}</span>
                      </small>
                    </b-form-group>

                    <b-form-group id="input-group-2" label="First Name:" label-for="input-2"
                                  class="m-0">
                      <b-form-input
                        id="input-2"
                        v-model="form.first_name"
                        v-validate="'required|min:2|max:60'"
                        name="First Name"
                        type="text"
                        class="m-0" />
                      <small v-if="errors" class="m-2">
                        <span class="text-danger">{{ errors.first('First Name') }}</span>
                      </small>
                    </b-form-group>

                    <b-form-group id="input-group-3" label="Last Name:" label-for="input-3"
                                  class="m-0">
                      <b-form-input
                        id="input-3"
                        v-model="form.last_name"
                        v-validate="'required|min:2|max:60'"
                        name="Last Name"
                        type="text"
                        class="m-0" />
                      <small v-if="errors" class="m-2">
                        <span class="text-danger">{{ errors.first('Last Name') }}</span>
                      </small>
                    </b-form-group>

                    <b-button id="submit-button" block variant="primary"
                              class="my-5" :disabled="!isFormValid()" @click="formSubmit()">
                      Submit
                    </b-button>
                  </b-form>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" class="m-0 p-0 d-none d-lg-block">
          <b-img :src="userType === 'personal' ?
            SPENV.CDN_URL + '/assets/client-app/skillsgap/skillsgap-individual-onboard.jpeg':
            SPENV.CDN_URL + '/assets/client-app/skillsgap/Invited+Member+Cover.jpeg'" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SPTest from '@/components/SPTests/SPTest';

export default {
  name: 'SkillsGap',
  components: { SPTest },
  data() {
    return {
      challengeAccepted: false,
      form: {
        email: null,
        first_name: null,
        last_name: null,
      },
      test: null,
      testGroupId: null,
      userType: null,
    };
  },
  created() {
    // Decode the url param
    this.testGroupId = this.$route.params['testGroupId'];

    // Send back if testBank does not exist
    if (this.$route.params['userType'] === 'personal' && !this.$store.getters['sptests/getTestBank'] && !this.$route.params['testGroupId']) {
      this.$router.push({
        name: 'skillsgapTestSelection',
        params: { userType: this.$route.params['userType'] }
      }).catch(err => {});
    } else {
      this.getTests();
    }
  },
  methods: {
    /**
     * Submits form data to create new 'user summary', if response is successful does a router push to the test
     *
     * @param {object} data -- the form data to create the 'user summary' from
     * @param {string} testGroupId -- represents the 'skillsgap_group' test id
     *
     * Note: the hash on the groupResultId is just used to obfuscate what we are doing (so end user can't easily
     * manipulate URL), does not have a security use
     *
     * @return {void} -- pushes to new page
     */
    createSummary(data, testGroupId) {
      let loader = this.$loading.show();
      data['group_test_uuid'] = testGroupId;
      this.$store.dispatch('sptests/createSummary', data).then( (response)=> {
        if ((response.status === 200 || response.status === 201) && response.data.uuid) {
          loader.hide();
          this.$router.push({
            name: 'tests',
            params: { groupTestId: testGroupId, groupResultId: response.data.uuid }
          }).catch(err => {});
        }
      });
    },
    /**
     * Checks the 'veeFields' and makes sure every one is valid
     * @return {boolean}
     */
    isFormValid() {
      return Object.keys(this.veeFields).every(key => this.veeFields[key].valid);
    },
    /**
     * Routes form data to correct API call.
     * IF user === 'business', goes straight to `createSummary`
     * ELSE IF user === 'personal', must create the 'group test' first (then makes summary)
     *
     * @return {void} -- calls 'createSummary`, which does a router push on successs
     */
    formSubmit() {
      if (!this.isFormValid()) {
        return;
      }
      let data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
      };
      if (this.userType === 'business') {
        this.createSummary(data, this.testGroupId);
      } else if (this.userType === 'personal') {
        data['test_name'] = this.test.name;
        data['source_test_id'] = atob(this.testGroupId);
        data['company_name'] = 'custom';
        data['user_title'] = 'custom';
        data['phone_number'] = 'custom';
        // IMPORTANT: designates individual user
        data['is_admin'] = false;
        data['idcustomer'] = null;
        this.$store.dispatch('sptests/saveGroup', data).then((response)=>{
          this.createSummary(data, response.data.uuid);
        });
      }
    },
    /**
     * Test name maybe be in two places, find the correct one and return it
     * @param {object} test -- the test parent, or the test merged with parent from the API
     * @return {string}
     */
    getTestName(test) {
      return test.hasOwnProperty('name') ? test.name: test.test_name;
    },
    /**
     * Depending on user type, a different test will need loaded
     *
     * If user is 'business', a group test has already been created. Load it
     * If user is 'personal' (individual), will need to get 'base test'
     *
     * @return {void} -- manipulates store/template
     */
    getTests() {
      this.userType = this.$route.params.userType;
      if (this.userType === 'business') {
        this.$store.dispatch('sptests/getTest', this.testGroupId).then(() => {
          this.test = this.$store.getters['sptests/getTest'];
        });
      } else if (this.userType === 'personal') {
        if (!this.test) {
          this.$store.dispatch('sptests/getBaseTests', {param1: 0, param2: 1, param3: 2}).then(() => {
            this.test = this.$store.getters['sptests/getTestBank'][atob(this.testGroupId)];
          });
        } else {
          this.test = this.$store.getters['sptests/getTestBank'][this.testGroupId];
        }
      }
    },
    /**
     * Changes the state of the template to display the form (for non-users), or if logged in, populates form and
     * triggers submit
     *
     * NOTE: `user.id` represents the assumption a valid user object exists
     *
     * @return {void}
     */
    nextStep() {
      if (this.user && this.user.first_name && this.user.last_name && this.user.email) {
        this.form.first_name = this.user.first_name;
        this.form.last_name = this.user.last_name;
        this.form.email = this.user.email;
        this.formSubmit();
      } else {
        this.challengeAccepted = true;
      }
    },
  }
};
</script>

<style lang="scss">
  #modal-test-info {
    .modal-header {
      padding: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-bottom: 0;
    }
  }
  .skillsgap-onboard {
    @media (min-width: 992px) {
      .prevent-shift {
        min-height: 85vh; // There's a slight shift between between the forms, this prevents it by forcing equal height
      }
    }
    .button {
      background: #64A33D;
      border-radius: 4px;
    }
    .bolded {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
    .container {
      max-width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    h3 {
      align-items: center;
      color: #414141;
    }
    link {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #64A33D;
    }
  }
</style>
