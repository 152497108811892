<template>
  <div>
    <b-container id="sp-test-questions" class="sp-test-questions">
      <b-row>
        <b-col cols="12">
          <h6 id="section-headline" class="m-2 mt-2">
            {{ question.section }}
          </h6>
          <h3 id="question-order" class="m-2">
            Question {{ question.order }}
          </h3>
          <b-row align-h="start">
            <b-col cols="12" md="6">
              <p id="question-text" class="m-2">
                {{ question.text }}
              </p>
            </b-col>
            <b-col v-if="question.image" cols="12" md="6"
                   :class="checkWindowWidth()">
              <b-img-lazy class="max-height" :src="SPENV.S3_URL + '/images/tests/' + question.image" fluid
                          :alt="'Image for ' + question.section" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Select an Answer">
            <b-form-radio-group
              id="radio-slots"
              v-model="selected">
              <b-row>
                <b-col v-for="(answer, value, index) in question.answers" :key="value" cols="12"
                       md="6" class="p-1 add-flex" @click="selected = value">
                  <b-row class="full-width">
                    <!-- NOTE: the 'answer-height' class will control height of the column depending on if there is text or not (picture only answers) -->
                    <b-col class="answer m-2 p-4" :class="{ selected: selected == value, 'answer-height': answer.text }" :tabindex="index + 1"
                           @keyup.enter="selected = value">
                      <b-row id="answer-parent" align-v="center">
                        <b-col cols="1" class="radio-padding">
                          <b-form-radio :value="value" />
                        </b-col>
                        <b-col v-if="answer.text">
                          <p class="m-0">
                            {{ answer.text }}
                          </p>
                        </b-col>
                        <b-col v-if="answer.image && answer.text" cols="3" class="text-right">
                          <b-img-lazy v-if="answer.image" :src="SPENV.S3_URL + '/images/tests/' + answer.image"
                                      fluid class="thumbnail max-height" :alt="'Image for ' + question.section" />
                        </b-col>
                        <b-col v-if="answer.image && !answer.text" cols="12" class="text-center">
                          <b-img-lazy v-if="answer.image" :src="SPENV.S3_URL + '/images/tests/' + answer.image"
                                      fluid class="large-image" :alt="'Image for ' + question.section" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form-radio-group>
          </b-form-group>
          <b-row class="pb-5">
            <b-col cols="6" md="3" class="text-left">
              <b-button
                v-if="currentQuestion !== 0"
                block
                variant="outline-primary"
                :tabindex="Object.keys(question.answers).length + 2"
                :class="{ hidden: currentQuestion === 0 }"
                @click="back()">
                Back
              </b-button>
            </b-col>
            <b-col cols="6" md="3" offset-md="6"
                   class="text-right">
              <b-button
                id="next-question-button"
                block
                :variant="buttonVariant()"
                :tabindex="Object.keys(question.answers).length + 1"
                :class="{ disabled: selected === null }"
                @click="next()">
                {{ currentQuestion + 1 == test.count ? 'Finish': 'Next' }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'SPTestQuestions',
  props: {
    'question': {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      nextVariant: 'disabled',
      selected: null,
    };
  },
  computed: {
    currentQuestion: {
      get: function () {
        return this.$store.getters['sptests/getCurrentQuestion'];
      },
      set: function (data) {
        this.$store.commit('sptests/setCurrentQuestion', data);
      }
    },
    test() {
      return this.$store.getters['sptests/getTest'];
    }
  },
  mounted() {
    if (this.currentQuestion && this.test && this.test.questions && this.test.questions[this.currentQuestion].submittedAnswer) {
      this.selected = this.test.questions[this.currentQuestion].submittedAnswer;
    }
  },
  methods: {
    back() {
      this.currentQuestion--;
    },
    buttonVariant() {
      if (this.selected !== null) {
        return 'primary';
      }
    },
    next() {
      if (this.selected === null) {
        return;
      }
      this.submitAnswer();
      if (this.currentQuestion + 1 === this.test.questions.length) {
        this.$bvModal.show('confirm-modal');
      } else {
        this.currentQuestion++;
      }
    },
    /**
     * Submits answer to API, and then updates the store with the recorded result
     *
     * @return {void}
     */
    submitAnswer() {
      if (this.selected !== null) {
        let data = {
          question_id: this.test.questions[this.currentQuestion].question_id,
          answer_id: this.selected,
          group_test_id: this.$route.params['groupTestId'],
          result_id: this.$route.params['groupResultId'],
          display_order: this.test.questions[this.currentQuestion].order,
          currentQuestion: this.currentQuestion
        };
        this.$store.dispatch('sptests/saveAnswer', data).then(()=>{
          window.scrollTo(0,0);
        });
      }
    },
    checkWindowWidth() {
      let boundClass = 'text-center';
      if (window.innerWidth > 676) {
        boundClass = 'text-right';
      }
      return boundClass;
    },
  },
};
</script>

<style lang="scss">
  .sp-test-questions {
    .add-flex {
      display: flex;
    }
    .answer {
      width: 100%;
      border-color: $sp-gray-300;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
    }
    .answer.selected {
      border-color: $sp_green;
    }
    .answer:hover, .answer:focus {
      border-color: $sp_green;
      cursor: pointer;
    }
    .answer:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(100,163,61,0.5)
    }
    .btn-disabled, .disabled {
      color: white;
      background-color: $sp-gray-800;
      border-color: rgba(0, 0, 0, 0);
    }
    .full-width {
      width: 100%;
    }
    .large-image {
      object-fit: cover;
      max-height: 15vh;
    }
    h3 {
      align-items: center;
      color: #414141;
    }
    .max-height {
      max-height: 25vh;
    }
    p {
      color: #414141;
    }
    .radio-padding {
      padding-top: 6px;
    }
    .thumbnail {
      object-fit: cover;
      height: 50px !important;
    }
  }
</style>
