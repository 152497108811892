<template>
  <b-modal id="confirm-modal" hide-header hide-footer
           class="sp-test-confirm-modal">
    <b-container>
      <b-row>
        <b-col>
          <h3>Ready to Submit?</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="my-4">
            You are about to submit your answers. This is your last chance to go back and review
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <b-button id="cancel-button" variant="outline-primary" block
                    @click="hideModal()">
            Back
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button id="finish-button" variant="primary" block
                    @click="closeTest()">
            Finish Test
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  name: 'SkillsgapConfirmModal',
  computed: {
    selectedSummary () {
      return this.$store.getters['sptests/getSelectedSummary'];
    },
  },
  created() {
    this.$store.dispatch('sptests/getIndividualSummary',  this.$route.params['groupResultId']);
  },
  methods: {
    closeTest() {
      this.$router.push({
        name: 'results',
        params: { groupTestUuid: this.$route.params['groupTestId'], groupResultUuid: this.$route.params['groupResultId'] }
      }).catch(err => {});
    },
    hideModal() {
      this.$bvModal.hide('confirm-modal');
    },
  }
};
</script>

<style lang="scss">
  @media (max-width: 991.98px) {
    #confirm-modal {
      width: 87%;
    }
  }
  .sp-test-confirm-modal {
    h3 {
      align-items: center;
      color: #414141;
    }
    p {
      color: #414141;
    }
  }
</style>
