<template>
  <div>
    <b-container id="sp-test" fluid class="timer-bar">
      <b-row v-if="test">
        <b-col id="test-name" cols="12" md="5"
               class="m-2 mx-5">
          {{ test.test_name }}
        </b-col>
        <b-col id="test-duration" class="text-right m-2 mx-5">
          <b>Duration {{ hhmmss(testDuration) }}</b>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="test && verified">
      <b-row>
        <b-col cols="12">
          <SPTestProgressBar
            v-if="currentQuestion < test.questions.length"
            :value="currentQuestion"
            :max="test.questions.length"
            class="my-4" />
          <div v-for="(question, index) in test.questions" :key="index">
            <SPTestQuestions v-if="index === currentQuestion" :question="question" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SkillsgapConfirmModal />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SPTestProgressBar from '@/components/SPTests/SPTestProgressBar';
import SkillsgapConfirmModal from '@/components/Skillsgap/SkillsgapConfirmModal.vue';
import SPTestQuestions from '@/components/SPTests/SPTestQuestions';
import moment from 'moment';

export default {
  name: 'SPTest',
  components: { SkillsgapConfirmModal, SPTestProgressBar, SPTestQuestions },
  data() {
    return {
      groupTestId: null,
      groupResultId: null,
      verified: false,
      testDuration: null,
      testStart: null,
      timer: null,
    };
  },
  computed: {
    currentQuestion() {
      return this.$store.getters['sptests/getCurrentQuestion'];
    },
    sections () {
      return this.$store.getters['sptests/getSections'];
    },
    test() {
      return this.$store.getters['sptests/getTest'];
    },
  },
  beforeCreate() {
    if (!this.$store.getters['sptests/getTest']) {
      let loader = this.$loading.show();
      this.$store.dispatch('sptests/getTest', this.$route.params['groupTestId']).then((response)=>{
        loader.hide();
      });
    }
    if (!this.$store.getters['sptests/getSections']) {
      this.$store.dispatch('sptests/getSections', this.$route.params['groupTestId']);
    }
  },
  created() {
    this.groupTestId = this.$route.params['groupTestId'];
    this.groupResultId = this.$route.params['groupResultId'];
    this.verifyTest();
    this.testStart = moment();
    this.timer = setInterval(this.startTimer, 1000);
  },
  beforeDestroy () {
    // IMPORTANT! This will go on forever if not cleared
    clearInterval(this.timer);
  },
  methods: {
    /**
     * Formats a raw second count to `hh:mm:ss` format
     *
     * @param {integer} secs
     * @return {string}
     */
    hhmmss(secs) {
      function pad(num) {
        return ('0' + num).slice(-2);
      }

      let minutes = Math.floor(secs / 60);
      secs = secs % 60;
      let hours = Math.floor(minutes/60);
      minutes = minutes % 60;
      return pad(hours) + ':' + pad(minutes) + ':' + pad(secs);
    },
    /**
     * Computes the time difference from `testStart` to now
     *
     * @return {void} -- manipulates `data`
     */
    startTimer() {
      this.testDuration = moment().diff(moment(this.testStart), 'seconds');
    },
    /**
     * Verify the test has not already been taken. If it has, push to results
     */
    verifyTest() {
      this.$store.dispatch('sptests/verifyTest', {groupTestId: this.groupTestId, groupResultId: this.groupResultId}).then((response)=>{
        this.verified = response.data.questions_remaining;
        if (!response.data.questions_remaining) {

          this.$router.push({
            name: 'results',
            params: { groupTestId: this.$route.params['groupTestId'], groupResultUuid: this.groupResultId }
          }).catch(err => {});
        }
      });
    }
  }
};
</script>

<style scoped>
.timer-bar {
  background-color: #F8F8F8;
}
</style>
