<template>
  <div id="test-progress-bar" class="sp-test-progress-bar">
    <b-container>
      <b-row v-if="sections">
        <b-col v-for="(section, index) in Object.values(sections)" :key="index" class="px-auto my-4">
          <b-row align-v="center">
            <b-col class="p-0">
              <b-progress :id="'progress-bar-' + index" :value="questionsRemaining(index)" :max="section.question_count"
                          height="8px" class="no-radius" />
              <div :id="'progress-icon-' + index" class="section-marker move-up text-right">
                <font-awesome-icon icon="circle" class="grey"
                                   :class="{ green: questionsRemaining(index) >= section.question_count }" />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TestProgressBar',
  props: {
    'value': {
      type: Number,
      default: null
    },
    'max': {
      type: Number,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    sections () {
      return this.$store.getters['sptests/getSections'];
    },
  },
  methods: {
    /**
     * Gets number of questions in a section by section index
     *
     * @param sectionNumber
     * @return {number}
     */
    getSectionQuestions(sectionNumber) {
      return Object.values(this.sections)[sectionNumber].question_count;
    },
    /**
     * For each section, signified by index, determines where the current question falls within it
     *
     * @param sectionIndex
     * @returns {number}
     */
    questionsRemaining(sectionIndex) {
      let tempIndex = sectionIndex;
      let questionCount = 0;

      while (tempIndex > 0) {
        questionCount += this.getSectionQuestions(tempIndex);
        tempIndex--;
      }

      return this.value - questionCount;
    },
  },
};
</script>
<style lang="scss">
  .sp-test-progress-bar {
    .section-marker {
      position: absolute;
      right: -2px;
      top: -8px;
    }
    .grey {
      color: #e9ecef;
    }
    .move-up {
      z-index: 20;
    }
    .green {
      color: $sp-green;
      transition: color 1.2s ease;
    }
    .no-radius {
      border-radius: 0;
    }
    .shift-left {
      left: 1px;
    }
  }
</style>
